import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Columns } from "react-bulma-components";
import prices from "../prices.json";
import { Helmet } from "react-helmet";
import prepaidPdf from "../documents/prepaid.pdf";
import loyalPdf from "../documents/loyal.pdf";
import monthlysubsPdf from "../documents/monthly_subs.pdf";

function PriceList({ setNavClass }) {
  const { t } = useTranslation();

  useEffect(() => {
    setNavClass("dark");
  }, []);

  // Definirea stilului aici
  const cellStyle = {
    textAlign: "center",
    verticalAlign: "middle",
  };

  const lineStyle = {
    margin: "0",
    padding: "0",
  };

  return (
    <>
      <Helmet>
        <title>North Tennis | {t("prices.list")}</title>
      </Helmet>
      <Columns className="snap-point" style={{ width: "100%" }}>
        <Columns.Column
          className="price-bg"
          tablet={{
            size: "one-third",
          }}
          desktop={{
            size: "one-third",
          }}
          widescreen={{
            size: "one-third",
          }}
          fullhd={{
            size: "one-third",
          }}
        ></Columns.Column>
        <Columns.Column
          tablet={{
            size: "two-thirds",
          }}
          desktop={{
            size: "two-third",
          }}
          widescreen={{
            size: "two-third",
          }}
          fullhd={{
            size: "two-third",
          }}
          style={{ backgroundColor: "#f7f7f7", paddingTop: "70px" }}
        >
          <div className={"pricePage"} style={{ marginLeft: "20px" }}>
            <h2 className="title has-text-left snap-point">
              {t("prices.openAir")}
            </h2>
            <h3 className="title has-text-left">{t("prices.bookCourt")}</h3>
            <div className="priceTable table-container">
              <table className="table is-bordered is-striped is-narrow is-hoverable">
                <thead>
                  <tr>
                    <th className={"has-text-left"}>{t("prices.hourNr")}</th>
                    <th>{t("prices.moFrMorning")}</th>
                    <th>{t("prices.moFrEvening")}</th>
                    <th>{t("prices.moFrMorningWeekend")}</th>
                    <th>{t("prices.moFrEveningWeekend")}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th className={"has-text-left"}>{t("prices.1hTitle")}</th>
                    <td>{prices.openAir.one.moFrMorning}</td>
                    <td>{prices.openAir.one.moFrEvening}</td>
                    <td>{prices.openAir.one.moFrMorningWeekend}</td>
                    <td>{prices.openAir.one.moFrEveningWeekend}</td>
                  </tr>
                  <tr>
                    <th className={"has-text-left"}>
                      {t("prices.abo10Title")}
                    </th>
                    <td>{prices.openAir.abo10.moFrMorning}</td>
                    <td>{prices.openAir.abo10.moFrEvening}</td>
                    <td>{prices.openAir.abo10.moFrMorningWeekend}</td>
                    <td>{prices.openAir.abo10.moFrEveningWeekend}</td>
                  </tr>
                  <tr>
                    <th className={"has-text-left"}>
                      {t("prices.abo50Title")}
                    </th>
                    <td>{prices.openAir.abo50.moFrMorning}</td>
                    <td>{prices.openAir.abo50.moFrEvening}</td>
                    <td>{prices.openAir.abo50.moFrMorningWeekend}</td>
                    <td>{prices.openAir.abo50.moFrEveningWeekend}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <h2 className="title has-text-left snap-point">
              {t("prices.indoor")}
            </h2>
            <h3 className="title has-text-left">{t("prices.bookCourt")}</h3>
            <div className="priceTable table-container padded-b">
              <table className="table is-bordered is-striped is-narrow is-hoverable ">
                <thead>
                  <tr>
                    <th>{t("prices.hourNr")}</th>
                    <th>{t("prices.moFrMorning")}</th>
                    <th>{t("prices.moFrEvening")}</th>
                    <th>{t("prices.moFrMorningWeekend")}</th>
                    <th>{t("prices.moFrEveningWeekend")}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>{t("prices.1hTitle")}</th>
                    <td>{prices.indoor.one.moFrMorning}</td>
                    <td>{prices.indoor.one.moFrEvening}</td>
                    <td>{prices.indoor.one.moFrMorningWeekend}</td>
                    <td>{prices.indoor.one.moFrEveningWeekend}</td>
                  </tr>
                  <tr>
                    <th>{t("prices.abo10Title")}</th>

                    <td>{prices.indoor.abo10.moFrMorning}</td>
                    <td>{prices.indoor.abo10.moFrEvening}</td>
                    <td>{prices.indoor.abo10.moFrMorningWeekend}</td>
                    <td>{prices.indoor.abo10.moFrEveningWeekend}</td>
                  </tr>
                  <tr>
                    <th>{t("prices.abo50Title")}</th>
                    <td>{prices.indoor.abo50.moFrMorning}</td>
                    <td>{prices.indoor.abo50.moFrEvening}</td>
                    <td>{prices.indoor.abo50.moFrMorningWeekend}</td>
                    <td>{prices.indoor.abo50.moFrEveningWeekend}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <h2
              className="title has-text-left snap-point"
              style={{ color: "green" }}
            >
              {t("prices.antrenorOra")}
            </h2>
            <h3 className="title has-text-left">{t("prices.abonamentSez")}</h3>
            <a class="docLink" href={prepaidPdf} download>
              {t("prices.prepaidLink")}
            </a>
            <p className={"has-text-left"}>{t("prices.aboSezpre")}</p>

            <div className="priceTable table-container">
              <table className="table is-bordered is-striped is-hoverable ">
                <thead>
                  <tr>
                    <th>{t("prices.hourWeek")}</th>
                    <th>{t("prices.hourTotal")}</th>
                    <th>{t("prices.hourIndivid")}</th>
                    <th>{t("prices.hourGroup")}</th>
                    <th>{t("prices.period")}</th>
                    <th>{t("prices.hourDispo")}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>{t("prices.oneHourweek")}</th>
                    <td>24</td>
                    <td style={cellStyle} rowspan="5">
                      170 RON
                    </td>
                    <td style={cellStyle} rowspan="5">
                      105 RON
                    </td>
                    <td style={cellStyle} rowspan="5">
                      {t("prices.sixMonth")}
                    </td>
                    <td style={cellStyle} rowspan="5">
                      {t("prices.luniDuminica")}
                    </td>
                  </tr>
                  <tr>
                    <th>{t("prices.twoHourweek")}</th>
                    <td>48</td>
                  </tr>
                  <tr>
                    <th>{t("prices.treeHourweek")}</th>
                    <td>72</td>
                  </tr>
                  <tr>
                    <th>{t("prices.fourHourweek")}</th>
                    <td>96</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className={"has-text-left padded-b"}>
              {t("prices.aboSezoferta")}
            </div>

      

            <h3 className="title has-text-left">{t("prices.aboLunar")}</h3>
            <a class="docLink" href={monthlysubsPdf} download>
              {t("prices.monthlysubsLink")}
            </a>
            <p className={"has-text-left"}>{t("prices.aboLunarred")}</p>

            <div className="priceTable table-container">
              <table className="table is-bordered is-striped is-hoverable ">
                <thead>
                  <tr>
                    <th>{t("prices.hourWeek")}</th>
                    <th>{t("prices.hourTotal")}</th>
                    <th>{t("prices.hourIndivid")}</th>
                    <th>{t("prices.hourGroup")}</th>
                    <th>{t("prices.period")}</th>
                    <th>{t("prices.hourDispo")}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>{t("prices.twoHourweek")}</th>
                    <td>8</td>
                    <td style={cellStyle} rowspan="4">
                      190 RON
                    </td>
                    <td style={cellStyle} rowspan="4">
                      115 RON
                    </td>
                    <td style={cellStyle} rowspan="4">
                      {t("prices.oneMonth")}
                    </td>
                    <td style={cellStyle} rowspan="4">
                      {t("prices.luniDuminicafull")}
                    </td>
                  </tr>
                  <tr>
                    <th>{t("prices.treeHourweek")}</th>
                    <td>12</td>
                  </tr>
                  <tr>
                    <th>{t("prices.fourHourweek")}</th>
                    <td>16</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <h3 className="title has-text-left">{t("prices.oraFaraabo")}</h3>
            <p className={"has-text-left"}>{t("prices.oraFaraaboexplc")}</p>

            <div className="priceTable table-container padded-b">
              <table className="table is-bordered is-striped is-hoverable ">
                <thead>
                  <tr>
                    <th>{t("prices.hourWeek")}</th>
                    <th>{t("prices.hourTotal")}</th>
                    <th>{t("prices.hourIndivid")}</th>
                    <th>{t("prices.hourGroup")}</th>
                    <th>{t("prices.period")}</th>
                    <th>{t("prices.hourDispo")}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>#</th>
                    <td>1-7</td>
                    <td style={cellStyle} rowspan="2">
                      220 RON
                    </td>
                    <td style={cellStyle} rowspan="2">
                      130 RON
                    </td>
                    <td style={cellStyle} rowspan="2">
                      0
                    </td>
                    <td style={cellStyle} rowspan="2">
                      {t("prices.limitaDispo")}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <h2 className={"title has-text-left snap-point"}>
              {t("prices.productsServices")}
            </h2>
            <div className="priceTable table-container">
              <table className="table is-bordered is-striped is-narrow is-hoverable  has-text-left">
                <thead>
                  <tr>
                    <th>{t("prices.product")}</th>
                    <th>{t("prices.price")}</th>
                    <th>{t("prices.notes")}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>{t("prices.overgrip")}</th>
                    <td>{prices.overgrip}</td>
                    <td>{t("prices.overgripNote")}</td>
                  </tr>
                  <tr>
                    <th>{t("prices.antiVibrator")}</th>
                    <td>{prices.antiVibrator}</td>
                    <td>{t("prices.antiVibratorNote")}</td>
                  </tr>
                  <tr>
                    <th>{t("prices.balls")}</th>
                    <td>{prices.ballsDunlop}</td>
                    <td>{t("prices.ballsDunlop")}</td>
                  </tr>
                  <tr>
                    <th>{t("prices.balls")}</th>
                    <td>{prices.ballsWilson}</td>
                    <td>{t("prices.ballsWilson")}</td>
                  </tr>
                  <tr>
                    <th>{t("prices.rewireOwn")}</th>
                    <td>{prices.rewireOwn}</td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>{t("prices.rewireHexaspin")}</th>
                    <td>{prices.rewireHexaspin}</td>
                    <td>{t("prices.rewireHexaspinNote")}</td>
                  </tr>{" "}
                  <tr>
                    <th>{t("prices.rewireLuxilon")}</th>
                    <td>{prices.rewireLuxilon}</td>
                    <td>{t("prices.rewireLuxilonNote")}</td>
                  </tr>
                  <tr>
                    <th>{t("prices.racquets")}</th>

                    <td>{t("prices.onDemand")}</td>
                    <td>{t("prices.racquetsNote")}</td>
                  </tr>
                  <tr>
                    <th>{t("prices.apparel")}</th>

                    <td>{t("prices.onDemand")}</td>
                    <td>{t("prices.apparelNote")}</td>
                  </tr>
                  <tr>
                    <th>{t("prices.videoAnalysis")}</th>

                    <td>{prices.videoAnalysis}</td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>{t("prices.videoRecording")}</th>

                    <td>{prices.videoRecording}</td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Columns.Column>
      </Columns>
    </>
  );
}

export default PriceList;
